import React from 'react';
import ReactSelect from 'react-select';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : 'black',
        backgroundColor: state.isFocused ? '#7d0043' : 'white',
    }),
    control: base => ({
        ...base,
        boxShadow: 'none',
    })
};

export const Select = ({ label, options, onSelect, value = null }) => {
    const val = options.map(item => {
        if (item.options) {
            return item.options.find(o => o.value === value);
        }

        return value === item.value ? item : null;
    }).filter(Boolean);

    return (
        <>
            <legend>{label}</legend>
            <ReactSelect
                placeholder={''}
                styles={customStyles}
                options={options}
                onChange={({ value }) => onSelect(value)}
                value={val || ''}
            />
        </>
    );
};
