import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { path } from 'ramda';

import { getTerm } from '../selectors';
import { saveData as saveDataAction } from '../actions';

import { TEST_STEPS, TEST_HEADER_TEXT, TEST_IMAGE, TEST_TYPE } from '../constants';
import { faCheckCircle, faEye } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Breadcrumbs, Button, Image, LoadingBox, OverlayModal, Tabs, TabButton, InputNumber, TextWrapper } from 'components';

import TestHeader from './TestHeader';
import ResultRate from './ResultRate';
import { useText, useTimer } from 'utils/hooks';

const getNext = ({ active, type, step }) => {
    if (type === TEST_TYPE.GROUP && step === TEST_STEPS.TEST) {
        return { active, step: TEST_STEPS.EVALUATION };
    }
    if (type === TEST_TYPE.GROUP && step === TEST_STEPS.EVALUATION) {
        return { active: active + 1, step: TEST_STEPS.TEST };
    }

    return { active: active + 1, step };
};

const TestLayout = ({ type, goHome, params, user }) => {
    const [time, run, isRunning] = useTimer();
    const [step, setStep] = useState(TEST_STEPS.PRE);
    const [completed, setCompleted] = useState([]);
    const [points, setPoints] = useState({});
    const [active, setActive] = useState(0);
    const { isLoading, data } = useSelector(getTerm);
    const dispatch = useDispatch();
    const getText = useText();
    const next = getNext({ active, step, type });

    const addPoints = value => setPoints({ ...points, [data[active].id]: value || 0 });

    const isNextDisabled = () => completed.includes(active) && type !== TEST_TYPE.GROUP
        ? !points[path([active, 'id'], data)]
        : false;

    const evaluate = () => {
        run(false);
        setStep(TEST_STEPS.EVALUATION);
        if (type === TEST_TYPE.FULL) {
            setActive(0);
        }
    };

    const nextTestTab = () => {
        setCompleted([...completed, active]);
        setActive(next.active);
        setStep(next.step);
    };

    const saveData = () => {
        if (user.isLoggedIn) {
            dispatch(saveDataAction({
                user_id: user.get(),
                completed,
                questions: data.map(({ id }) => id),
                points,
                select: { type, ...params },
                time_count: time,
            }));
        }
    };

    const nextEvalTab = (e) => {
        if(isNextDisabled()) {
            return;
        }

        if (!e.key || e.key === 'Enter') {
            e.preventDefault();

            if (active + 1 < data.length) {
                setActive(next.active);
                setStep(next.step);
            } else {
                setStep(TEST_STEPS.RESULTS);
                saveData();
            }
        }
    };

    return (
        <>
            <hr className="m-0" />
            <Breadcrumbs className="container my-4" items={params} />
            <div className="container my-4">
                <LoadingBox
                    isLoading={isLoading}
                    data={data}
                    active={active}
                    content={item => (
                        <>
                            <TestHeader
                                testType={type}
                                timer={{ time, run, isRunning }}
                                onEvaluate={evaluate}
                                step={step}
                                completedCount={completed.length}
                                graphicSolutions={
                                    data.map(({ graphic_solution_img_code }, index) => ({ question_id: index+1, graphic_solution_img_code}))
                                        .filter(i => Boolean(i.graphic_solution_img_code))
                                }
                                params={params}
                                item={item}
                            />
                            <Tabs data={data} active={active} setActive={setActive} step={step} completed={completed} />
                            {item && step !== TEST_STEPS.PRE &&
                                <Box gray className="p-4 text-center">
                                    <h2>{TEST_HEADER_TEXT[step]}</h2>
                                    <div>
                                        {item.test_code}
                                        {!TEST_STEPS.RESULTS &&
                                            <span>| Úloha č. {active+1}</span>
                                        }
                                    </div>
                                    {type === TEST_TYPE.GROUP &&
                                        <i className="mt-4">(Číselná označení úloh v obrázku jsou ilustrační)</i>
                                    }
                                    {step === TEST_STEPS.RESULTS &&
                                        <ResultRate data={data} points={points} />
                                    }
                                </Box>
                            }
                            {item && TEST_IMAGE[step] &&
                                <Box>
                                    <Image name={item[TEST_IMAGE[step]]} />
                                </Box>
                            }
                            {step === TEST_STEPS.PRE &&
                                <>
                                    {type === TEST_TYPE.FULL &&
                                        <TextWrapper dangerouslySetInnerHTML={{ __html: getText('FULL_TEST_TEXT_PROLOG') }} />
                                    }
                                    {type === TEST_TYPE.GROUP && !data.filter(i => i.graphic_solution_img_code).length &&
                                        <TextWrapper dangerouslySetInnerHTML={{ __html: getText('GROUP_TEST_TEXT_PROLOG') }} />
                                    }
                                    {type === TEST_TYPE.GROUP && Boolean(data.filter(i => i.graphic_solution_img_code).length) &&
                                        <TextWrapper dangerouslySetInnerHTML={{ __html: getText('GROUP_TEST_TEXT_PROLOG') }} />
                                    }
                                    <div className="text-center mt-5 pt-4">
                                        <Button big text="Zahájit procvičování" onClick={() => {
                                            setStep(TEST_STEPS.TEST);
                                            run(true);
                                        }} />
                                    </div>
                                </>
                            }
                            <div className="text-center">
                                {step === TEST_STEPS.TEST && type === TEST_TYPE.GROUP &&
                                    <OverlayModal
                                        buttonText={[<FontAwesomeIcon key={0} icon={faEye} />, ' Náhled záznamového archu']}
                                        className="mx-2"
                                    >
                                        <Image name={item.answer_img_code} />
                                    </OverlayModal>
                                }
                                {step === TEST_STEPS.TEST &&
                                    <TabButton
                                        className="mt-3 mt-md-0"
                                        active={active}
                                        last={data.length}
                                        nextText={[<FontAwesomeIcon key={0} icon={faCheckCircle} />, ' Hotovo']}
                                        finishText={[<FontAwesomeIcon key={0} icon={faCheckCircle} />, ' Hotovo']}
                                        onNext={nextTestTab}
                                        onFinish={() => {
                                            setCompleted([...completed, active]);
                                            evaluate();
                                        }}
                                    />
                                }
                                {step === TEST_STEPS.EVALUATION && type === TEST_TYPE.FULL &&
                                    <>
                                        Počet dosažených bodů
                                        <InputNumber
                                            value={points[item.id] || ''}
                                            minValue={0}
                                            maxValue={item.point_total}
                                            enabledValues={item.point_combination}
                                            onKeyDown={nextEvalTab}
                                            onValidate={addPoints}
                                            className="ml-2 mr-0"
                                            autofocus={item.id}
                                            disabled={!completed.includes(active)}
                                        />
                                    </>
                                }
                                {step === TEST_STEPS.RESULTS &&
                                    <Button text="Vybrat další test" onClick={goHome} />
                                }
                            </div>
                            {step === TEST_STEPS.EVALUATION &&
                                <div className="text-center mt-5">
                                    <OverlayModal buttonText="Zadání úlohy" className="mx-2">
                                        <Image name={item.assignment_img_code} />
                                    </OverlayModal>
                                    <OverlayModal buttonText="Vzorové řešení" className="mt-3 mt-md-0 mx-2" disabled={!item.solution_img_code}>
                                        <Image name={item.solution_img_code} />
                                    </OverlayModal>
                                    {(active + 1 !== data.length || step !== TEST_STEPS.EVALUATION || type !== TEST_TYPE.GROUP) &&
                                        <Button text="Další úloha" className="mt-3 mt-md-0 mx-2" disabled={isNextDisabled()} onClick={nextEvalTab}/>
                                    }
                                </div>
                            }
                            {active + 1 === data.length && step === TEST_STEPS.EVALUATION && type === TEST_TYPE.GROUP &&
                                <div className="text-center">
                                    <Button big text="Vybrat další sadu" className="mt-4 mx-2" onClick={() => {
                                        saveData();
                                        goHome();
                                    }} />
                                </div>
                            }
                        </>
                    )}
                />
            </div>
        </>
    );
};

export default TestLayout;
