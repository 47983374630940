import React from 'react';
import styled from 'styled-components';
import { declension } from 'utils/textTransform';

const StyledText = styled.span`
    font-size: 22px;
    color: #7d0043;
`;

const ResultRate = ({ data, points }) => {
    const pointsMaxCount = data.reduce((accumulator, item) => (accumulator + parseInt(item.point_total)), 0);
    const pointsCount = Object.values(points).reduce((accumulator, item) => (accumulator + parseInt(item)), 0);
    const successRate = Math.round(100/pointsMaxCount*pointsCount);

    return (
        <div className="pt-4">
            <b>
                <StyledText>{pointsCount}</StyledText> {declension(pointsCount, 'bod')} z <StyledText>{pointsMaxCount}</StyledText> možných<br />
                úspěšnost <StyledText>{successRate} %</StyledText>
            </b>
        </div>
    );
};

export default ResultRate;
