import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux/hooks';
import reducer, { REDUCER_KEY } from '../reducer';
import { loadTerms, loadGroupSets } from '../actions';

import Layout from '../components/Layout';

const Container = (props) => {
    const dispatch = useDispatch();

    useInjectReducer(REDUCER_KEY, reducer);

    useEffect(() => {
        dispatch(loadTerms());
        dispatch(loadGroupSets());
    }, [dispatch]);

    return <Layout {...props} />;
};

export default Container;
