import createReducer from 'utils/redux/createReducer';
import { createAsyncAction, createInitState } from 'utils/redux/utils';
import { REDUCER_KEY, GET_TERM } from './constants';

const ACTION_HANDLERS = {
    ...createAsyncAction({ key: GET_TERM }),
};

const initialState = {
    ...createInitState(),
};

export {
    REDUCER_KEY,
};

export default createReducer(initialState, ACTION_HANDLERS);
