import React from 'react';
import styled from 'styled-components';

const StyledBox = styled.div.attrs({
    className: 'p-4 mb-4',
})`
    display: block;
    border-radius: ${props => props.radiusLess ? '0' : '25px'};
    background-color: #f1f3f5;
`;

export const Box = ({ gray, ...otherProps }) =>
    <StyledBox style={{ backgroundColor: gray ? '#f1f3f5' : '#fff' }} {...otherProps} />;

export const BoxInner = styled.div`
  width: 400px;
  max-width: 100%;
  margin: auto;
`;
