import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TEST_STEPS, TEST_TYPE } from '../constants';
import { faCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { formatBytes } from 'utils/formaters';

import { Box, Button, Timer } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const STUDY_SHORTCUT = {
    4: 9,
    6: 7,
    8: 5,
}

const StyledDownloadBtn = styled(Link)`
    color: #7d0043 !important;
    text-decoration: underline;
`;

const StyledDownloadBtnPre = styled.span`
    color: grey !important;
`;

const getFileSize = ({ url, onLoad }) => {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', url, true);
    xhr.onreadystatechange = () => onLoad(xhr.getResponseHeader('Content-Length'));
    xhr.send();
};

const getFileName = ({ studyLength, subject, group, groupSet }) =>
    `${subject === 'MA' ? 'M' : 'C'}${STUDY_SHORTCUT[parseInt(studyLength, 10)]}P${group}S${groupSet}`;

const TestHeader = ({
    timer: {
        time,
        run,
        isRunning
    },
    params,
    testType,
    onEvaluate: evaluate,
    item,
    step,
    completedCount,
    graphicSolutions
}) => {
    const [archFileSize, setArchFileSize] = useState(0);
    const [testFileSize, setTestFileSize] = useState(0);
    const group_arch_url = `/pdf.php?filename=${getFileName(params)}&${graphicSolutions.map(i => `images[${i.question_id}]=${i.graphic_solution_img_code}`).join('&')}`;
    const arch_url = `/files/${item.test_code}.pdf`;
    const test_url = `/files/Z_${item.test_code}.pdf`;

    useEffect(() => {
        getFileSize({ url: test_url, onLoad: setTestFileSize });
        getFileSize({
            url: testType === TEST_TYPE.GROUP ? group_arch_url : arch_url,
            onLoad: setArchFileSize,
        });
    }, [item, test_url, group_arch_url, arch_url, testType]);

    return (
        <Box gray>
            {testType === TEST_TYPE.GROUP && Boolean(graphicSolutions.length) &&
                <div className="flex-md-column" style={{paddingTop: '6px'}}>
                    <StyledDownloadBtn to={group_arch_url} target="_blank">
                        <FontAwesomeIcon icon={faDownload}/> Podklad pro vypracování úloh
                    </StyledDownloadBtn>
                    {' '}[PDF, {formatBytes(archFileSize)}]
                </div>
            }
            {testType === TEST_TYPE.FULL &&
                <div className="text-center text-md-left d-md-flex">
                    <div className="flex-md-column py-2 py-md-0" style={{paddingTop: '6px'}}>
                        {step === TEST_STEPS.PRE ? (
                            <StyledDownloadBtnPre>
                                <FontAwesomeIcon icon={faDownload}/> Didaktický test
                            </StyledDownloadBtnPre>
                        ) : (
                            <StyledDownloadBtn to={test_url} target="_blank" download>
                                <FontAwesomeIcon icon={faDownload}/> Didaktický test
                            </StyledDownloadBtn>
                        )}
                        {' '}[PDF, {formatBytes(testFileSize)}]
                    </div>
                    <div className="flex-md-column py-2 py-md-0 pl-md-5" style={{paddingTop: '6px'}}>
                        <StyledDownloadBtn to={arch_url} target="_blank">
                            <FontAwesomeIcon icon={faDownload}/> Záznamový arch
                        </StyledDownloadBtn>
                        {' '}[PDF, {formatBytes(archFileSize)}]
                    </div>
                    <div className="flex-md-column py-2 py-md-0 px-md-5">
                        <Timer
                            time={time}
                            run={run}
                            isRunning={isRunning}
                            disabled={step !== TEST_STEPS.TEST}
                        />
                    </div>
                    <div className="flex-md-column py-2 py-md-0">
                        <Button
                            disabled={!(step === TEST_STEPS.TEST && completedCount)}
                            onClick={evaluate}
                            text={[<FontAwesomeIcon key={0} icon={faCircle} className="pr-1"/>, ' Vyhodnotit']}/>
                    </div>
                </div>
            }
        </Box>
    );
};

export default TestHeader;
