import { get } from 'utils/request';
import { GET_LOGS, GET_TERMS } from './constants';

export const loadData = userId => ({
    type: GET_LOGS,
    payload: get(`/api/user-data?userId=${userId}`),
});

export const loadTerms = () => ({
    type: GET_TERMS,
    payload: get('/api/terms-points'),
});
