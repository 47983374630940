import createReducer from 'utils/redux/createReducer';
import { createAsyncAction, createInitState } from 'utils/redux/utils';
import { GET_USER_ID, USER_ID, REDUCER_KEY } from './constants';

const ACTION_HANDLERS = {
    ...createAsyncAction({ key: GET_USER_ID, parent: USER_ID }),
};

const initialState = {
    [USER_ID]: createInitState(''),
};

export {
    REDUCER_KEY,
};

export default createReducer(initialState, ACTION_HANDLERS);
