const baseFetchOptions = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
});

export const get = url => {
    const fetchOptions = {
        ...baseFetchOptions,
        method: 'GET',
    };

    return fetch(url, fetchOptions).then(res => res.json());
};

export const post = (url, data) => {
    const fetchOptions = {
        ...baseFetchOptions,
        method: 'POST',
        body: JSON.stringify(data),
    };

    fetch(url, fetchOptions).then(res => res.json());
};
