import React from 'react';
import styled from 'styled-components';

import { useText } from 'utils/hooks';

const StyledLink = styled.a`
  color: #7d0043 !important;
  text-decoration: underline;
`;

const getParamTexts = texts => ({
    studyLength: ({ studyLength }) => texts(`STUDY_LENGTH_${studyLength}`),
    subject: ({ subject }) => texts(`SUBJECT_${subject}`),
    group: ({ subject, group }) => texts(`GROUP_${subject}_${group}`),
    groupSet: ({ groupSet }) => `Sada ${groupSet}`,
    term: ({ term }) => term,
    type: ({ type }) => texts(`TEST_FORM_${type.toString().toUpperCase()}`),
    text: ({ text }) => text,
});

export const Breadcrumbs = ({ items, ...otherProps }) => {
    const texts = getParamTexts(useText());
    const breads = Object.keys(items).filter(key => Boolean(items[key])).map(key => texts[key](items)).join(' / ');

    return (
        <div {...otherProps}>
            <small>
                <StyledLink href="/">Procvičování testů a úloh</StyledLink> / {breads}
            </small>
        </div>
    );
};
