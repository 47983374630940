const MODULE = 'Test';

export const REDUCER_KEY = 'testReducer';
export const GET_TERM = `${MODULE}_GET_TERM`;
export const SAVE_TERM = `${MODULE}_SAVE_TERM`;

export const TEST_STEPS = {
    PRE: 'TEST_STEP_PRE',
    TEST: 'TEST_STEP_TEST',
    EVALUATION: 'TEST_STEP_EVALUATION',
    RESULTS: 'TEST_STEP_RESULTS',
};

export const TEST_HEADER_TEXT = {
    [TEST_STEPS.TEST]: 'Zadání úlohy',
    [TEST_STEPS.EVALUATION]: 'Vyhodnocení',
    [TEST_STEPS.RESULTS]: 'Vyhodnocení',
};

export const TEST_IMAGE = {
    [TEST_STEPS.TEST]: 'assignment_img_code',
    [TEST_STEPS.EVALUATION]: 'result_img_code',
    [TEST_STEPS.RESULTS]: false,
};

export const TEST_TYPE = {
    FULL: 'full',
    GROUP: 'group',
};
