import createReducer from 'utils/redux/createReducer';
import { createAsyncAction, createInitState } from 'utils/redux/utils';
import { GET_TERMS, GET_GROUP_SETS, REDUCER_KEY, TERMS, GROUP_SETS, SELECT_DATA } from './constants';
import { evolve, mergeLeft } from 'ramda';

const ACTION_HANDLERS = {
    ...createAsyncAction({ key: GET_TERMS, parent: TERMS }),
    ...createAsyncAction({ key: GET_GROUP_SETS, parent: GROUP_SETS }),
    [SELECT_DATA]: (state, { payload }) => evolve({
        select: data => mergeLeft(payload, data),
    }, state),
};

const initialState = {
    [TERMS]: createInitState(),
    [GROUP_SETS]: createInitState(),
    select: {
        isRunning: false,
        testType: '',
        studyLength: '',
        subject: '',
        groupSet: null,
        group: null,
        year: null,
        term: null,
    },
};

export {
    REDUCER_KEY,
};

export default createReducer(initialState, ACTION_HANDLERS);
