import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { TEST_STEPS } from 'routes/Test/constants';

const StyledButton = styled.button`
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: black;
    font-size: 18px;
    border-radius: 50%;
    background-color: white;
    border: none;
    margin: 10px;
    &.completed {
      background-color: #7d0043;
      color: white;
    }
`;

const StyledBox = styled.div.attrs({
    className: 'p-3 mb-3',
})`
    display: block;
    border-radius: 25px;
    background-color: #f1f3f5;
    margin-bottom: 80px !important;
`;

export const Tabs = ({ active, setActive, data, step,  completed }) => {
    const isCompleted = id => completed.includes(id);

    return (
        <StyledBox>
            {data.map(({ id }, i) =>
                <StyledButton
                    disabled={step === TEST_STEPS.RESULTS}
                    key={i}
                    className={classnames({ completed: isCompleted(i), active: active === i })}
                    onClick={() => setActive(i)}
                >
                    {i+1}
                </StyledButton>
            )}
        </StyledBox>
    );
};
