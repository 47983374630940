import { REDUCER_KEY, TERMS, GROUP_SETS } from './constants';
import { path } from 'ramda';

export const getTerms = state => ({ studyLength, subject }) => {
    const data = path([REDUCER_KEY, TERMS, 'data', studyLength, subject], state) || {};

    return Object.entries(data).sort(([a], [b]) => b.localeCompare(a)).map(([year, terms]) => ({
        label: year,
        options: Object.entries(terms).sort(([a], [b]) => a.localeCompare(b)).map(([key, term]) => ({
            label: term,
            value: key
        }))
    }));
};

export const getGroupSetsCount = state => ({ studyLength, subject, group }) => {
    const length = path([REDUCER_KEY, GROUP_SETS, 'data', studyLength, subject, group], state) || 0;
    return Array(parseInt(length)).fill(null).map((_, i) => ({ label: i+1, value: i+1 }));
};

export const getSelectData = state => state[REDUCER_KEY].select;
