import { get } from 'utils/request';
import { GET_TERMS, GET_GROUP_SETS, SELECT_DATA } from './constants';

export const loadTerms = () => ({
    type: GET_TERMS,
    payload: get('/api/terms'),
});

export const loadGroupSets = () => ({
    type: GET_GROUP_SETS,
    payload: get('/api/group-sets'),
});

export const changeSelectData = (payload) => ({
    type: SELECT_DATA,
    payload,
});
