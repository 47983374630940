import React  from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const StyledBtn = styled.button.attrs(props => ({
    className: classNames(props.big ? 'px-4 py-3' : 'px-3 py-2', props.classNames),
}))`
    display: inline-block;
    color: white;
    background-color: #7d0043;
    border-radius: 50px;
    border: none;
    outline: none !important;  
    &.outlined {
        color: black;
        background-color: white;
        border: 3px solid #7d0043;
    }
    &[disabled] {
        background-color: #c5c5c5 !important;
    }
`;

export const Button = ({ onClick, text, isVisible = true, disabled = false, ...otherProps }) => (
    <>
        {isVisible &&
            <StyledBtn {...otherProps} disabled={disabled} onClick={onClick}>{text}</StyledBtn>
        }
    </>
);

export const TabButton = ({ active, last, onNext, onFinish, nextText, finishText, ...otherProps }) => ++active < last
    ? <Button type="submit" text={nextText} onClick={onNext} {...otherProps} />
    : <Button type="submit" text={finishText} onClick={onFinish} {...otherProps} />
