import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux/hooks';
import { withRouter } from 'react-router-dom';

import TestLayout from '../components/TestLayout';

import reducer, { REDUCER_KEY } from '../reducer';
import { loadGroup, loadTerm } from '../actions';
import { TEST_TYPE } from '../constants';

const Container = ({ history, user, match: { params: { type, ...otherParams } } }) => {
    const dispatch = useDispatch();
    const goHome = () => history.push('/');

    useInjectReducer(REDUCER_KEY, reducer);

    useEffect(() => {
        if (type === TEST_TYPE.FULL) {
            dispatch(loadTerm(otherParams));
        }
        if (type === TEST_TYPE.GROUP) {
            dispatch(loadGroup(otherParams));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <TestLayout type={type} user={user} params={{ type, ...otherParams}} goHome={goHome} />;
};

export default withRouter(Container);
