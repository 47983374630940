import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button} from './Button';

const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    opacity: 0.8;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
`;
const OverlayWrapper = styled.div`
    display: inline-block;
    z-index: 9999;
    height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
`;
const Body = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 100px;
    max-width: 100%;
    max-height: 100vh;
    overflow-y: auto;
    @media only screen and (max-width: 767px) {
       padding: 100px 0 0 0;
    }
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    position: fixed;
    top: 20px;
    right: 30px;
    color: white;
    cursor: pointer;
    opacity: 0.5;
    z-index: 99999;
    &:hover {
      opacity: 1;
    }
`;

export const OverlayModal = ({ buttonText, children, ...otherProps }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onEscKeyPress = (e) => {
        if(e.keyCode === 27) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', onEscKeyPress, false);

        return () => {
            document.removeEventListener('keydown', onEscKeyPress, false);
        };
    }, []);

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return (
        <>
            <Button text={buttonText} {...otherProps} onClick={() => setIsOpen(true)} />
            {isOpen &&
                <OverlayWrapper>
                    <Overlay />
                    <StyledFontAwesomeIcon onClick={() => setIsOpen(false)} icon={faTimes} size="3x" />
                    <Body>
                        <div className="container">
                            {children}
                        </div>
                    </Body>
                </OverlayWrapper>
            }
        </>
    );
};
