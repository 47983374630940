export const createAsyncAction = ({ key, onStart, onSuccess, parent }) => ({
    [`${key}_START`]: state => {
        if (onStart) return onStart;

        return parent
            ? ({ ...state, [parent]: { ...state[parent], isLoading: true } })
            : ({ ...state, isLoading: true })
    },
    [`${key}_SUCCESS`]: (state, { payload: { data } }) => {
        if (onSuccess) return onSuccess;

        return parent
            ? ({ ...state, [parent]: { ...state[parent], isLoading: false, data } })
            : ({ ...state, isLoading: false, data })
    },
});

export const createInitState = (dataDefault = {}) => ({
    isLoading: false,
    data: dataDefault,
});
