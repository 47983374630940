import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import textsReducer from './reducers/texts/reducer';
import userReducer from './reducers/user/reducer';

const initReducers = {
    textsReducer,
    userReducer,
};

const createReducer = asyncReducers => combineReducers({
    ...initReducers,
    ...asyncReducers
});

export const promiseMiddleware = ({ dispatch }) => next => (action) => {
    const payloadIsPromise = action.payload && typeof action.payload.then === 'function';

    if (!payloadIsPromise) {
        return next(action);
    }

    const createAction = (suffix, actionPayload) => ({
        type: `${action.type}_${suffix}`,
        payload: actionPayload,
    });

    action.payload.then(value => dispatch(createAction('SUCCESS', value)));
    return next(createAction('START', null));
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 20 }) || compose; // eslint-disable-line

const configureStore = (initialState) => {
    const store = createStore(
        createReducer(),
        initialState,
        composeEnhancers(applyMiddleware(thunk, promiseMiddleware))
    );

    store.asyncReducers = {};

    store.injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(store.asyncReducers));
    };

    return store;
};

export default configureStore;
