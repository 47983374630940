import createReducer from 'utils/redux/createReducer';
import { createAsyncAction, createInitState } from 'utils/redux/utils';
import { REDUCER_KEY, GET_LOGS, GET_TERMS, TERMS, LOGS } from './constants';

const ACTION_HANDLERS = {
    ...createAsyncAction({ key: GET_LOGS, parent: LOGS }),
    ...createAsyncAction({ key: GET_TERMS, parent: TERMS }),
};

const initialState = {
    [LOGS]: createInitState(),
    [TERMS]: createInitState(),
};

export {
    REDUCER_KEY,
};

export default createReducer(initialState, ACTION_HANDLERS);
