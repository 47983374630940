import React from 'react';
import { useSelector } from 'react-redux';
import { getLogs, getTerms } from '../selectors';
import { Box, Breadcrumbs } from 'components';
import { TEST_TYPE } from 'routes/Test/constants';
import { LogItem } from './LogItem';
import { declension } from 'utils/textTransform';

const Layout = () => {
    const logs = useSelector(getLogs);
    const terms = useSelector(getTerms);
    const getDate = date => new Date(date);
    const getTerm = testCode => terms.data.find(({ test_code }) => test_code === testCode);
    const countPoints = points => Object.values(points).reduce((acc, value) => acc + parseInt(value, 10), 0);
    const getTestByType = type => logs.data.filter(item => JSON.parse(item.select).type === type);
    const getSuccessRate = item => Math.round(100/getTerm(JSON.parse(item.select).term).point_count*countPoints(JSON.parse(item.points)));

    if(logs.isLoading || !Boolean(logs.data.length) || terms.isLoading || !Boolean(terms.data.length)) {
        return <></>;
    }

    return (
        <>
            <hr className="m-0" />
            <div className="container">
                <Breadcrumbs items={{ text: 'přehled procvičování testů a úloh' }} className="my-4" />
                <Box gray className="mt-4 p-4 text-center">
                    <h2>Přehled procvičování testů a úloh</h2>
                    <span>Vznik účtu {getDate(logs.data[logs.data.length - 1].created.replace(' ', 'T')).toLocaleDateString('cs-CZ')}</span>
                </Box>
                {Boolean(getTestByType(TEST_TYPE.FULL).length) &&
                    <Box gray className="mt-4 p-4 text-center">
                        <h2>Celý test</h2>
                        <i>Řazeno vzestupně, kliknutím na test zahájíte nové procvičování</i>
                        <div className="mt-4">
                            {getTestByType(TEST_TYPE.FULL).map((item, key) => (
                                <div className="row pb-4 pb-md-0" key={key}>
                                    <div className="col-md-2 py-1 py-md-0">
                                        {getDate(item.created.replace(' ', 'T')).toLocaleDateString('cs-CZ')}
                                    </div>
                                    <div className="col-md-8 py-1 py-md-0 text-md-left">
                                        <LogItem items={JSON.parse(item.select)} terms={terms.data} />
                                    </div>
                                    <div className="col-md-2 py-1 py-md-0">
                                        {countPoints(JSON.parse(item.points))}{' '}
                                        {declension(countPoints(JSON.parse(item.points)), 'bod')}{' '}
                                        z {getTerm(JSON.parse(item.select).term).point_count}
                                        {' '}({getSuccessRate(item)} %)
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Box>
                }
                {Boolean(getTestByType(TEST_TYPE.GROUP).length) &&
                    <Box gray className="mt-4 p-4 text-center">
                        <h2>Výběr ze skupiny úloh</h2>
                        <i>Řazeno vzestupně, kliknutím na test zahájíte nové procvičování</i>
                        <div className="mt-4">
                            {getTestByType(TEST_TYPE.GROUP).map((item, key) => (
                                <div className="row pb-4 pb-md-0" key={key}>
                                    <div className="col-md-2 py-1 py-md-0">
                                        {getDate(item.created).toLocaleDateString('cs-CZ')}
                                    </div>
                                    <div className="col-md-10 py-1 py-md-0 text-md-left">
                                        <LogItem items={JSON.parse(item.select)} terms={terms.data} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Box>
                }
            </div>
        </>
    );
};

export default Layout;
