import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faStopwatch } from '@fortawesome/free-solid-svg-icons';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${props => props.disabled ? '#c5c5c5' : '#7d0043'};
  cursor: pointer;
`;

export const Timer = ({ time: { hours, minutes, seconds }, run, isRunning, disabled = false }) => {

    return (
        <>
            <StyledFontAwesomeIcon
                disabled={disabled}
                icon={faStopwatch}
                style={{
                    fontSize: '24px',
                    position: 'relative',
                    top: '3px'
                }}
            />
            <div className="mx-2 d-inline-block" style={{ minWidth: '55px' }}>{hours}:{minutes}:{seconds}</div>
            <StyledFontAwesomeIcon
                disabled={disabled}
                onClick={() => run(disabled ? false : !isRunning)}
                icon={isRunning ? faPause : faPlay}
            />
        </>
    );
};
