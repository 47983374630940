import React from 'react';
import { Image, IMG_SUFFIX, TextWrapper } from 'components';
import styled from 'styled-components';

const ALink = styled.a`
    color: #881653 !important;
    text-decoration: underline;
`;

const Container = () => (
    <>
        <Image name='slider' suffix={IMG_SUFFIX.JPG} />
        <div className="container">
            <TextWrapper>
                <div className="item-page" id="page-277">
                    <h2 className="mt-5 mb-3">Prohlášení o přístupnosti</h2>
                    <p>
                        Centrum pro zjišťování výsledků vzdělávání se zavazuje k zpřístupnění své webové stránky v
                        souladu s vnitrostátními právními předpisy, které provádějí Směrnici Evropského parlamentu a
                        Rady (EU) 2016/2102.
                    </p>
                    <p>
                        Toto prohlášení o přístupnosti se vztahuje na webovou stránku
                        https://prijimacky.cermat.cz.
                    </p>
                    <p>
                        <strong>Stav souladu</strong><br />
                        Tato webová stránka je plně v souladu se{' '}
                        <ALink
                            title="nové okno"
                            href="https://eur-lex.europa.eu/legal-content/CS/TXT/PDF/?uri=CELEX:32016L2102&amp;from=CS"
                            target="_blank" rel="noopener"
                        >
                            Směrnicí Evropského parlamentu a Rady (EU) 2016/2102 ze dne
                            26. října 2016 o přístupnosti webových stránek a mobilních aplikací subjektů veřejného
                            sektoru (Úř. věst. L 327, 2.12.2016, s. 1).
                        </ALink>
                    </p>
                    <p>
                        <strong>Informace prezentované v jiných formátech</strong><br />
                        Některé dokumenty a informace
                        jsou z důvodu rozsáhlosti uveřejněny ve formátu PDF nebo v některém z formátů aplikací
                        Microsoft Office (.doc, .xls).
                    </p>
                    <p>
                        <strong>Vypracování tohoto prohlášení o přístupnosti</strong><br />
                        Bylo provedeno vlastní
                        posouzení a ověření funkcí webových stránek, které je v souladu se Směrnicí Evropského
                        parlamentu a Rady (EU) 2016/2102.<br />Toto prohlášení bylo vypracováno dne 1. 9. 2019.
                    </p>
                    <p>
                        <strong>Zpětná vazba a kontaktní údaje</strong><br />
                        Vaše náměty, postřehy či informace o
                        problémech, na které narazíte při používání těchto webových stránek můžete poslat na
                        adresu:
                        <ALink href="mailto:info@cermat.cz"> info@cermat.cz</ALink>
                    </p>
                </div>
            </TextWrapper>
        </div>
    </>
);

export default Container;
