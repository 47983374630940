import createReducer from 'utils/redux/createReducer';
import { GET_TEXTS } from './constants';
import { createAsyncAction, createInitState } from 'utils/redux/utils';

const ACTION_HANDLERS = {
    ...createAsyncAction({ key: GET_TEXTS }),
};

const initialState = createInitState();

export default createReducer(initialState, ACTION_HANDLERS);
