import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useText } from 'utils/hooks';

import { getGroupSetsCount, getTerms, getSelectData } from '../selectors';

import { Box, BoxInner, Button, Image, IMG_SUFFIX, RadioSelector, Select, TextInput } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { changeSelectData } from '../actions';

const TEST_FORM_FULL = 'TEST_FORM_FULL';
const TEST_FORM_GROUP = 'TEST_FORM_GROUP';

const STUDY_LENGTH_LIST = [4, 6, 8];
const GROUPS_LIST = ['A', 'B', 'C', 'D'];
const SUBJECTS_LIST = ['MA', 'CJ'];
const TEST_FORM_LIST = [TEST_FORM_FULL, TEST_FORM_GROUP];

const StyledContent = styled.div`
    max-width: 830px;
    margin: auto;
`;

const Layout = ({ history, user }) => {
    const { isRunning, studyLength, subject, groupSet, group, term, testType } = useSelector(getSelectData);
    const getGroupSetsCountData = useSelector(getGroupSetsCount);
    const getTermsData = useSelector(getTerms);
    const dispatch = useDispatch();
    const getText = useText();
    const isRunTestDisabled = !(studyLength && subject && ((group && groupSet) || term));
    const setSelectData = item => dispatch(changeSelectData(item));

    const runTest = () => {
        setSelectData({ isRunning: true });
        history.push(testType === TEST_FORM_GROUP
            ? `test/group/${studyLength}/${subject}/${group}/${groupSet}`
            : `test/full/${studyLength}/${subject}/${term}`
        );
    };

    useEffect(() => {
        if(!isRunning) {
            setSelectData({ group: '' });
        }
        setSelectData({ term: '' });
        setSelectData({ groupSet: '' });
        setSelectData({ isRunning: false });
    }, [studyLength, testType, subject]); // eslint-disable-line

    return (
        <>
            <Image name='slider' suffix={IMG_SUFFIX.JPG} />
            <div className="container my-5">
                {!user.isLoggedIn && (
                    <>
                        <StyledContent>
                            <div dangerouslySetInnerHTML={{ __html: getText('HOMEPAGE_TEXT_OVER_LOGIN') }} />
                        </StyledContent>

                        <div className="text-center my-5">
                            {user.new.get() ? (
                                <b>
                                    Váš kód je {user.new.get()}
                                </b>
                            ) : (
                                <b>

                                    Nemáte-li kód pro přihlášení, zde jej můžete získat{' '}<br /><br />
                                    <Button onClick={() => user.create()} text="Získat kód" />
                                </b>
                            )}
                        </div>
                        <div className="text-center my-5">
                            <b>
                                Mate-li kód pro přihlášení, zadejte jej zde{' '}<br /><br />
                                <TextInput value={user.get()} onChange={e => user.set(e.target.value)} />
                                <Button onClick={() => user.login()} text="Přihlásit" />
                            </b>
                        </div>
                    </>
                )}

                <StyledContent>
                    <div dangerouslySetInnerHTML={{ __html: getText('HOMEPAGE_TEXT_UNDER_LOGIN') }} />
                </StyledContent>
                <StyledContent>
                    <div dangerouslySetInnerHTML={{ __html: getText('HOMEPAGE_SELECT_TEST_OR_GROUP_DESCRIPTION') }} />
                </StyledContent>

                <Box gray>
                    <BoxInner>
                        <RadioSelector
                            label="Vyberte test pro"
                            value={studyLength}
                            options={STUDY_LENGTH_LIST.map(value => ({ value, label: getText(`STUDY_LENGTH_${value}`) }))}
                            onSelect={value => setSelectData({ studyLength: value })}
                        />
                    </BoxInner>
                </Box>
                <Box gray>
                    <BoxInner>
                        <RadioSelector
                            label="Předmět"
                            value={subject}
                            options={SUBJECTS_LIST.map(value => ({ value, label: getText(`SUBJECT_${value}`) }))}
                            onSelect={value => setSelectData({ subject: value })}
                        />
                    </BoxInner>
                </Box>
                <Box gray>
                    <BoxInner>
                        <RadioSelector
                            label="Forma testu"
                            value={testType}
                            options={TEST_FORM_LIST.map(value => ({ value, label: getText(value)}))}
                            onSelect={value => setSelectData({ testType: value })}
                        />
                    </BoxInner>
                </Box>
                {studyLength && subject && testType &&
                    <Box gray>
                        <BoxInner>
                            {testType === TEST_FORM_FULL &&
                                <Select
                                    label="Vyberte test"
                                    value={term}
                                    options={getTermsData({ studyLength, subject })}
                                    onSelect={value => setSelectData({ term: value })}
                                />
                            }
                            {testType === TEST_FORM_GROUP &&
                                <>
                                    <Select
                                        label="Vyberte skupinu úloh"
                                        value={group}
                                        options={GROUPS_LIST.map(value => ({
                                            label: getText(`GROUP_${subject}_${value}`),
                                            value
                                        }))}
                                        onSelect={value => setSelectData({ group: value })}
                                    />
                                </>
                            }
                        </BoxInner>
                    </Box>
                }
                {group &&
                    <Box gray>
                        <BoxInner>
                            <Select
                                label="Vyberte sadu"
                                value={groupSet}
                                options={getGroupSetsCountData({ studyLength, subject, group })}
                                onSelect={value => setSelectData({ groupSet: value })}
                            />
                        </BoxInner>
                    </Box>
                }
                <Button
                    big
                    text={(
                        <span>
                            <FontAwesomeIcon icon={faBookOpen} /> Otevřít test
                        </span>
                    )}
                    disabled={isRunTestDisabled}
                    onClick={() => runTest()}
                    className="m-auto d-block"
                />
            </div>
        </>
    );
};

export default withRouter(Layout);
