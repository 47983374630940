const slugify = (text) => text
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');

const declension = (number, text) => {
    if(number === 1) {
        return text;
    }

    if(number > 1 && number <= 4) {
        return `${text}y`;
    }

    return `${text}ů`;
};

export {
    slugify,
    declension,
};
