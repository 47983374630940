import { get, post } from 'utils/request';
import { GET_TERM, SAVE_TERM } from './constants';

const paramsToUri = params => Object.keys(params).map(key => key + '=' + params[key]).join('&');

export const loadTerm = params => ({
    type: GET_TERM,
    payload: get(`/api/term?${paramsToUri(params)}`),
});

export const loadGroup = params => ({
    type: GET_TERM,
    payload: get(`/api/group?${paramsToUri(params)}`),
});

export const saveData = params => ({
    type: SAVE_TERM,
    payload: post('/api/save-data', params),
});
