import React from 'react';
import { useText } from 'utils/hooks';
import { Image, IMG_SUFFIX, TextWrapper } from 'components';

const Container = () => {
    const getText = useText();
    return (
        <>
            <Image name='slider' suffix={IMG_SUFFIX.JPG} />
            <div className="container">
                <TextWrapper>
                    <h2 className="mt-5 mb-3">Prohlášení o použití cookies</h2>
                    <div className="my-4" dangerouslySetInnerHTML={{ __html: getText('COOKIES') }} />
                </TextWrapper>
            </div>
        </>
    );
};

export default Container;
