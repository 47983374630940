import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
    background-color: white;
    border-radius: 30px;
    border: 2px solid #7d0043;
    padding: 6px 15px;
    min-width: 220px;
    margin-right: 15px;
    outline: none !important;
    &[disabled] {
        background-color: #f1f3f5;
        border-color: #c5c5c5;
    }
`;

const validator = ({ minValue, maxValue, enabledValuesList, value }) => {
    if (typeof minValue !== 'undefined' && parseInt(value) < parseInt(minValue)) {
        return parseInt(minValue).toString();
    } else if (typeof maxValue !== 'undefined' && parseInt(value) > parseInt(maxValue)) {
        return parseInt(maxValue).toString();
    } else if (typeof enabledValuesList !== 'undefined' && !enabledValuesList.includes(parseInt(value))) {
        return '';
    }

    return parseInt(value).toString();
};

export const InputNumber = ({
    placeholder,
    value: defaultValue,
    onValidate,
    minValue,
    maxValue,
    enabledValues,
    autofocus = false,
    ...otherProps
}) => {
    const enabledValuesList = [...new Set(enabledValues.split('').map(item => parseInt(item)))];
    const validate = (val) => validator({ minValue, maxValue, enabledValuesList, value: val });
    const preventChars = (e) => {
        e = e || window.event;
        const charCode = (typeof e.which == 'undefined') ? e.keyCode : e.which;
        const charStr = String.fromCharCode(charCode);

        if (!charStr.match(/^[0-9]+$/))
            e.preventDefault();
    };
    const ref = useRef();
    useEffect(() => {
        if (autofocus) {
            ref.current.focus();
        }
    }, [autofocus]);

    return (
        <StyledInput
            {...otherProps}
            type="number"
            placeholder={placeholder}
            value={defaultValue}
            onKeyPress={preventChars}
            onChange={(e) => onValidate(validate(e.target.value))}
            ref={ref}
        />
    );
};
