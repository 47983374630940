import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux/hooks';
import { withRouter } from 'react-router-dom';

import LogsLayout from '../components/Layout';

import reducer, { REDUCER_KEY } from '../reducer';
import { loadData, loadTerms } from '../actions';

const Container = ({ history, user }) => {
    const dispatch = useDispatch();
    const goHome = () => history.push('/');

    useInjectReducer(REDUCER_KEY, reducer);

    useEffect(() => {
        dispatch(loadData(user.get()));
        dispatch(loadTerms());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <LogsLayout goHome={goHome} />;
};

export default withRouter(Container);
