import React, {useEffect} from 'react';
import styled from 'styled-components';
import { Provider, useDispatch } from 'react-redux';
import configureStore from './utils/redux/store';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Options from './routes/Options';
import Test from './routes/Test';
import Logs from './routes/Logs';
import Cookies from './routes/Cookies';
import AccessibilityStatement from './routes/AccessibilityStatement';

import { loadTexts } from 'utils/redux/reducers/texts/actions';
import { Image } from 'components/Image';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Box } from 'components/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEnvelopeOpen, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { useUser } from 'utils/hooks';
import { CookiesBanner } from "./components/CookiesBanner";

const Header = styled.h1`
    margin-top: 5px;
    margin-bottom: 0;
    border-left: 5px solid #7d0043;
    text-transform: uppercase;
    font-size: 22px;
    margin-left: 45px;
    padding-left: 15px;
    padding-top: 4px;
    padding-bottom: 7px;
    font-weight: 600;
    a {
      text-decoration: none !important;
      color: black !important;
    }
    @media only screen and (max-width: 767px) {
       margin-left: 0 !important;
       margin-top: 20px;
       margin-bottom: 20px;
    }
`;

const Body = styled.div`
    font-size: 14px;
    background-color: #fafafa;
    font-family: Roboto, sans-serif;
`;

const ALink = styled.a`
    color: #881653 !important;
    text-decoration: underline;
`;

const AText = styled.span`
    color: black;
    text-decoration: underline;
`;

const UserWrapper = styled.span`
    position: relative;
    top: -6px;
`;

const store = configureStore();

const routes = [
    {
        path: [
            '/test/:type/:studyLength/:subject/:group/:groupSet',
            '/test/:type/:studyLength/:subject/:term',
        ],
        Component: Test,
    },
    {
        path: '/logs',
        Component: Logs,
    },
    {
        path: '/cookies',
        Component: Cookies,
    },
    {
        path: '/prohlaseni-o-pristupnosti-aplikace',
        Component: AccessibilityStatement,
    },
    {
        path: '/',
        Component: Options,
    },
];

const EntryPoint = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadTexts());
    }, [dispatch]);

    return (
        <Router>
            <div>
                <Switch>
                    {routes.map(({ path, Component }, index) => (
                        <Route path={path} key={index}>
                            <Component {...props} />
                        </Route>
                    ))}
                </Switch>
                <CookiesBanner />
            </div>
        </Router>
    );
};


const UserButton = styled.a`
    background: none;
    border: none;
    color: #881653 !important;
    padding: 0;
    text-decoration: underline;
`;

const App = () => (
    <Provider store={store}>
        <Layout />
    </Provider>
);

const Layout = () => {
    const user = useUser();

    return (
        <Body>
            <div className="container py-4">
                <div className="row text-center">
                    <div className="col-md-3">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.cermat.cz">
                            <Image name='logo' className="img-fluid" style={{ maxHeight: '45px' }} />
                        </a>
                    </div>
                    <div className="col-md-6">
                        <Header>
                            <a href="/">
                                Procvičování testů a úloh
                            </a>
                        </Header>
                    </div>
                    <div className="col-md-3 pt-2">
                        {user.isLoggedIn ? (
                            <>
                                <span className="fa-layers fa-fw fa-2x mr-2">
                                    <FontAwesomeIcon icon={faCircle} color="#881653" />
                                    <FontAwesomeIcon icon={faUser} color="white" transform="shrink-8" />
                                </span>
                                <UserWrapper>
                                    <UserButton href="/logs">{user.get()}</UserButton>
                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                    <UserButton href="/" onClick={() => user.logout()}>Odhlásit</UserButton>
                                </UserWrapper>
                            </>
                        ) : (
                            <>Uživatel nepřihlášen</>
                        )}
                    </div>
                </div>
            </div>
            <EntryPoint user={user} />
            <Box gray radiusLess className='py-5 mt-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 first-footer d-flex">
                            <div className="mb-2 flex-column">
                                <Image name="logo_footer" alt="logo footer" width="59" height="58" />
                            </div>
                            <div className="flex-column ml-2">
                                Centrum pro zjišťování výsledků vzdělávání<br />
                                Jankovcova 933/63<br />
                                170 00 Praha 7 - Holešovice
                            </div>
                        </div>
                        <div className="col-md-3 mt-3 mt-md-0">
                            <div className="dial">
                                <a href="tel:+420224507507" className="d-flex mb-1">
                                    <span className="fa-layers fa-fw fa-2x flex-column">
                                        <FontAwesomeIcon icon={faCircle} color="#3c3f49" />
                                        <FontAwesomeIcon icon={faPhone} flip="horizontal" color="white" transform="shrink-8" />
                                    </span>
                                    <AText className="flex-column p-1">224 507 507</AText>
                                </a>
                                <a href="mailto:info@cermat.cz" className="d-flex">
                                    <span className="fa-layers fa-fw fa-2x flex-column">
                                        <FontAwesomeIcon icon={faCircle} color="#3c3f49" />
                                        <FontAwesomeIcon icon={faEnvelopeOpen} color="white" transform="shrink-8" />
                                    </span>
                                    <AText className="flex-column p-1">info@cermat.cz</AText>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 mt-4 mt-md-0">
                            <ALink
                                href="https://www.facebook.com/udelammaturitu/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="d-flex mb-1"
                            >
                                <FontAwesomeIcon icon={faFacebookSquare} color="#4267b2" className="flex-column" size="2x" />
                                <span className="flex-column p-1 ml-1">Udělám&nbsp;maturitu</span>
                            </ALink>
                            <ALink
                                href="https://www.facebook.com/jednotneprijimacky/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="d-flex"
                            >
                                <FontAwesomeIcon icon={faFacebookSquare} color="#4267b2" className="flex-column" size="2x" />
                                <span className="flex-column p-1 ml-1">Jednotné&nbsp;přijímačky</span>
                            </ALink>
                        </div>
                    </div>
                </div>
            </Box>
            <small className="d-block text-center pb-2">
                Centrum pro zjišťování výsledků vzdělávání | © 2020 Všechna práva vyhrazena
            </small>
            <small className="d-block text-center pb-4">
                <ALink href="/prohlaseni-o-pristupnosti-aplikace">Prohlášení o přístupnosti</ALink>
                {' '}|{' '}
                <ALink href="/cookies">Cookies</ALink>
                {' '}|{' '}
                <ALink href="https://prijimacky.cermat.cz/files/files/dokumenty/Aplikace/CZVV_Podminky_pouzivani_aplikace.pdf" target="_blank">
                    Podmínky používání aplikace [PDF, 125 kB]
                </ALink>
            </small>
        </Body>
    );
}

export default App;
