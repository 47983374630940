import React from 'react';
import styled from 'styled-components';

import { useText } from 'utils/hooks';

const StyledLink = styled.a`
  color: #7d0043 !important;
  text-decoration: underline;
`;

const getParamTexts = texts => ({
    studyLength: ({ studyLength }) => texts(`STUDY_LENGTH_${studyLength}`),
    subject: ({ subject }) => texts(`SUBJECT_${subject}`),
    group: ({ subject, group }) => texts(`GROUP_${subject}_${group}`),
    groupSet: ({ groupSet }) => `Sada ${groupSet}`,
});

export const LogItem = ({ items: { type, term, groupSet, ...items }, terms, ...otherProps }) => {
    const texts = getParamTexts(useText());
    const breads = Object.keys(items).filter(key => Boolean(items[key])).map(key => texts[key](items)).join(' / ');

    const getTest = testCode => terms.find(term => term.test_code === testCode);

    return (
        <div {...otherProps}>
            {term &&
                <>
                    {breads} / {getTest(term).year} / <StyledLink href={`/test/full/${Object.values({ ...items, term }).join('/')}`}>{getTest(term).term}</StyledLink>
                </>
            }
            {groupSet &&
                <>
                    {breads} / <StyledLink href={`/test/group/${Object.values({ ...items, groupSet }).join('/')}`}>Sada {groupSet}</StyledLink>
                </>
            }
        </div>
    );
};
