import React from 'react';
import classNames from 'classnames';

const PROXY_URL = '/';//http://procvicprijimacky.zdarsko.cz/';

export const IMG_SUFFIX = {
    PNG: '.png',
    JPG: '.jpg',
};

export const Image = ({ name, suffix = IMG_SUFFIX.PNG, className, ...otherProps }) => (
    <img src={`${PROXY_URL}images/${name}${suffix}`} {...otherProps} className={classNames('img-fluid', className)} alt={name} />
);
