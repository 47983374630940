import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getText } from './redux/reducers/texts/selectors';
import { getUser } from './redux/reducers/user/selectors';
import { createUserId } from './redux/reducers/user/actions';

export const useText = () => useSelector(getText);

export const useTimer = () => {
    const [time, setTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const toTwoDigits = digit => digit <= 9 ? `0${digit}` : digit.toString();

    useEffect(() => {
        const interval = setInterval(() => {
            if(isRunning) {
                setTime(time+1);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [isRunning, setTime, time]);

    const run = state => setIsRunning(state);

    const timeData = {
        hours: toTwoDigits(Math.floor(time/3600)),
        minutes: toTwoDigits(Math.floor(time/60) % 60),
        seconds: toTwoDigits(time % 60),
    };

    return [timeData, run, isRunning];
};

export const useUser = () => {
    const [newId, setNewId] = useState('');
    const [id, setId] = useState(window.localStorage.getItem('userId') || '');
    const [isLoggedIn, setIsLoggedIn] = useState(Boolean(window.localStorage.getItem('userId')));
    const dispatch = useDispatch();
    const user = useSelector(getUser);

    useEffect(() => {
        setNewId(user.data);
    }, [user]);

    const create = () => dispatch(createUserId());
    const login = () => {
        window.localStorage.setItem('userId', id);
        setIsLoggedIn(true);
    };
    const logout = () => {
        window.localStorage.clear();
        setIsLoggedIn(false);
        setNewId('');
        setId('');
    };

    return {
        get: () => id,
        set: setId,
        create,
        isLoggedIn,
        login,
        logout,
        new: {
            get: () => newId
        }
    };
};
