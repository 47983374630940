import React from 'react';
import styled from 'styled-components';
import { slugify } from 'utils/textTransform'

const StyledInputRadio = styled.input`
    -moz-appearance: initial;
    position: absolute;
    width: 12px;
    height: 12px;
    top: 8px;

    &::after {
        width: 18px;
        height: 18px;
        border-radius: 20px;
        top: -3px;
        left: -2px;
        position: relative;
        background-color: white;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 3px solid white;
    }
    &[checked]::after {
        border-color: #7d0043 !important;
    }
`;

export const RadioSelector = ({ label, value, options, onSelect }) => (
    <>
        <h5>{label}</h5>
        {options.map(option => (
            <div key={option.value} className="pt-1 position-relative">
                <StyledInputRadio
                    type="radio"
                    name={slugify(label)}
                    value={option.value}
                    onClick={() => onSelect(option.value)}
                    defaultChecked={option.value === value}
                />
                <span className="ml-4 pl-1" style={{ cursor: 'pointer' }} onClick={() => onSelect(option.value)}>{option.label}</span>
            </div>
        ))}
    </>
);
