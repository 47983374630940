import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
    background-color: white;
    border-radius: 30px;
    border: 2px solid #7d0043;
    padding: 6px 15px;
    min-width: 220px;
    margin-right: 15px;
    outline: none !important;
    &[disabled] {
        background-color: #f1f3f5;
        border-color: #c5c5c5;
    }
`;

export const TextInput = ({ value, onChange, ...otherProps }) => (
    <StyledInput
        {...otherProps}
        type="text"
        value={value}
        onChange={onChange}
    />
);
