import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

const CookiesWrapper = styled.div`
  #cookies #accept-cookies {
    background-color: #7d0043;
    color: white;
  }
  #cookies #config-cookies,
  #cookies #save-cookies {
    background-color: #eaeff2;
    color: #7d0043;
  }
  .cookies-wrapper p a {
    color: #7d0043;
  }

  #cookies #accept-cookies:hover {
    background-color: #580130;
  }
  #cookies #config-cookies:hover {
    background-color: #ced1d3;
  }

  #cookies h4 {
    color: #7d0043;
  }
  #cookies .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #7d0043;
    border-color: #580130;
  }

  #cookies {
    position: fixed;
    width: 100%;
    z-index: 9999;
    bottom: 0;
  }
  #cookies p {
    color: black !important;
    text-align: left;
  }
  #cookies.open {
    top: 0;
  }
  #cookies > .container {
    height: 100%;
    display: flex;
  }
  #cookies #cookies-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9998;
    display: block;
    visibility: hidden;
    opacity: 0;
    height: 100%;
    transition: opacity .25s ease!important;
    background-color: black;
  }
  #cookies #cookies-overlay.active {
    visibility: visible;
    opacity: 0.8;
  }
  #cookies .cookies-wrapper {
    background-color: white;
    padding: 20px 40px;
    box-shadow: 0 0.625em 1.875em rgb(2 2 3 / 28%);
    border-radius: 6px;
    position: relative;
    z-index: 9999;
    max-height: 75vh;
    overflow: hidden;
  }
  #cookies .cookies-page {
    display: none !important;
    flex-direction: column;
  }
  #cookies .cookies-page.active {
    display: flex !important;
  }
  #cookies h4 {
    font-size: 1.1em;
  }
  #cookies p {
    margin-bottom: 0;
    font-size: .85em;
  }
  #cookies #accept-cookies,
  #cookies #config-cookies,
  #cookies #save-cookies {
    width: 100%;
    font-size: .8em;
    padding: 1em 1.6em;
    text-align: center;
    border-radius: 4px;
    border: none;
    outline: none !important;
    transition: background-color .25s ease!important;
    cursor: pointer;
  }
  #cookies #accept-cookies {
    margin-bottom: 5px;
  }
  #cookies #cookies-close {
    padding: 0;
    width: 44px;
    height: 44px;
    font-size: 25px;
    margin: 0;
    font-weight: initial;
    position: absolute;
    right: 40px;
    top: 20px;
    border: none !important;
    background-color: #eaeff2;
    outline: none !important;
    transition: background-color .25s ease!important;
    cursor: pointer;
    border-radius: 6px;
  }
  #cookies #cookies-close:hover {
    background-color: #ced1d3;
  }
  #cookies #cookies-close::before,
  #cookies #cookies-close::after {
    content: '';
    position: absolute;
    left: .82em;
    top: .55em;
    height: 17px;
    width: 1.5px;
    margin: 0 auto;
  }
  #cookies #cookies-close::before {
    background-color: #444d;
    transform: rotate(45deg);
  }
  #cookies #cookies-close::after {
    background-color: #444d;
    transform: rotate(-45deg);
  }
  #cookies #cookies-content-wrapper {
    overflow: auto;
    max-height: calc(100% - 132px);
    padding: 20px 0;
  }
  #cookies .cookies-header {
    display: flex;
    align-items: center;
    height: 44px;
    margin-bottom: 15px;
  }
  #cookies .cookies-header h4 {
    margin: 0;
  }
  #cookies #cookies-footer-wrapper button {
    max-width: 200px;
  }
  #cookies .cookies-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
  #cookies .cookies-divider {
    border: 1px solid #eaeff2;
    width: calc(100% + 78px);
    margin: 0 -40px;
  }
  #cookies #cookies-config {
    justify-content: center;
  }
  #cookies #cookies-config > p {
    margin-bottom: 30px;
  }
  #cookies #cookies-info {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  #cookies .cookies-info-wrapper {
    margin-top: 10px;
    padding: 0;
    border: none;
    border-radius: 6px;
    background: #f0f4f7;
    transition: none;
  }
  #cookies .cookies-info-wrapper button {
    display: flex;
    justify-content: space-between;
    font-family: inherit;
    font-size: 1em;
    width: 100%;
    cursor: pointer;
    position: relative;
    padding: 20px 35px 20px 20px;
    background: #f0f4f7;
    transition: background-color .25s ease;
    border: none;
    box-shadow: none !important;
    height: 70px;
  }
  #cookies .cookies-info-wrapper button h4 {
    padding-top: 5px;
    margin: 0 !important;
  }
  #cookies .cookies-info-wrapper button h4 .chevron {
    font-size: 0.8em;
    margin-right: 15px;
  }
  #cookies .cookies-info-wrapper button:not(.collapsed) h4 .chevron:before {
    content: "\f077";
  }
  #cookies .cookies-info-wrapper button.collapsed h4 .chevron:before {
    content: "\f078";
  }
  #cookies .cookies-info-wrapper button:hover {
    background-color: #dce0e3;
  }
  #cookies .cookies-info-wrapper .card-body {
    border: none;
    background: transparent;
    border-radius: 0;
  }
  #cookies .cookies-info-wrapper .check {
    width: 3.6em;
    height: 1.6em;
    border-radius: 4em;
    background-color: #7d0043;
  }
  #cookies .custom-switch .custom-control-label::before {
    width: 50px;
    height: 25px;
    border-radius: 25px;
    box-shadow: none !important;
  }
  #cookies .custom-switch .custom-control-label::after {
    height: 21px;
    width: 21px;
    border-radius: 21px;
  }
  #cookies .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(25px);
  }
  #cookies .cookies-info-button {
    position: relative;
  }
  #cookies .cookies-info-button .custom-switch {
    position: absolute;
    right: 35px;
    top: 19px;
  }
`;

export const CookiesBanner = () => {
    const [showCookies, setShowCookies] = useState(true);
    const [cookiesInfoClass, setCookiesInfoClass] = useState('active');
    const [cookiesConfigClass, setCookiesConfigClass] = useState('');
    const [cookiesOverlayClass, setCookiesOverlayClass] = useState('');
    const [cookiesClass, setCookiesClass] = useState('');
    const [analyticsColapse, setAnalyticsColapse] = useState(false);

    const configCookies = () => {
        setCookiesInfoClass('');
        setCookiesConfigClass('active');
        setCookiesOverlayClass('active');
        setCookiesClass('open');
    }
    const closeCookies = () => {
        setCookiesInfoClass('active');
        setCookiesConfigClass('');
        setCookiesOverlayClass('');
        setCookiesClass('');
    }

    const saveCookies = () => {
        document.cookie = 'cookies={"level": ["necessary"]}';
        setShowCookies(false);
    }

    useLayoutEffect(() => {
        if(document.cookie.includes('cookies') > 0) {
            setShowCookies(false);
        }
    },[]);

    if (!showCookies) {
        return null;
    }

    return (
        <CookiesWrapper>
            <div id="cookies" className={cookiesClass}>
                <div className="container">
                    <div id="cookies-info" className={`cookies-page ${cookiesInfoClass}`}>
                        <div className="cookies-wrapper">
                            <div className="row">
                                <div className="col-md-8">
                                    <h4>Informace o cookies</h4>
                                    <p>Když kliknete na &quot;Přijmout všechny soubory cookie&quot;, poskytnete tím souhlas k jejich ukládání na vašem zařízení, což pomáhá s navigací na stránce, s analýzou využití dat a s našimi marketingovými snahami. Váš souhlas můžete kdykoliv odvolat v Nastavení cookies. Další informace naleznete v sekci <a href="https://prijimacky.cermat.cz/zpracovani-osobnich-udaju" target="_blank">Informace o zpracování údajů na webu.</a></p>
                                </div>
                                <div className="col-md-4">
                                    <button id="accept-cookies" onClick={saveCookies}>Přijmout všechny soubory cookies</button>
                                    <button id="config-cookies" onClick={configCookies}>Nastavení cookies</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="cookies-config" className={`cookies-page ${cookiesConfigClass}`}>
                        <div className="cookies-wrapper">
                            <div id="cookies-header-wrapper">
                                <div className="cookies-header">
                                    <h4>Nastavení cookies</h4>
                                    <button id="cookies-close" onClick={closeCookies} />
                                </div>
                                <hr className="cookies-divider"/>
                            </div>
                            <div id="cookies-content-wrapper">
                                <h4>Používáme následující soubory cookies</h4>
                                <p>Při návštěvě jakékoli webové stránky je pravděpodobné, že stránka získá nebo uloží informace na vašem prohlížeči, a to většinou ve formě souborů cookie. Můžou to být informace týkající se vás, vašich preferencí a zařízení, které používáte. Většinou to slouží k vylepšování stránky, aby fungovala podle vašich očekávání. Informace vás zpravidla neidentifikují jako jednotlivce, ale celkově mohou pomoci přizpůsobovat prostředí vašim potřebám. Respektujeme vaše právo na soukromí, a proto se můžete rozhodnout, že některé soubory cookie nebudete akceptovat. Když kliknete na různé tituly, dozvíte se více a budete moci nastavení změnit. Nezapomínejte ale na to, že zablokováním některých souborů cookie můžete ovlivnit, jak stránka funguje a jaké služby jsou vám nabízeny.</p>
                                <div className="cookies-info-wrapper">
                                    <div className="cookies-info-button">
                                        <button className="btn btn-primary collapsed" type="button" data-toggle="collapse"
                                                data-target="#importantCookies" aria-expanded="false"
                                                aria-controls="importantCookies">
                                            <h4> Funkční cookies
                                            </h4>
                                        </button>
                                        <div className="custom-switch">
                                            <input type="checkbox" className="custom-control-input" disabled checked
                                                   id="importantCookiesSwitch" />
                                                <label className="custom-control-label" htmlFor="importantCookiesSwitch" />
                                        </div>
                                    </div>
                                    <div className="collapse" id="importantCookies">
                                        <div className="card card-body">Tyto cookies jsou nezbytné k tomu, aby Webové stránky fungovaly, takže není možné je vypnout. Většinou jsou nastavené jako odezva na akci, kterou na Webových stránkách sami provedete, jako je např. bezpečnostní nastavení, přihlašování, vyplňování formulářů. Prohlížeč můžete nastavit tak, aby blokoval soubory cookies nebo o nich posílal upozornění. Mějte na paměti, že některé stránky bez těchto souborů nebudou fungovat. Tyto soubory cookies neukládají žádné informace přiřaditelné ke konkrétní osobě. Tyto cookies můžeme nastavovat my nebo poskytovatelé třetí strany, jejichž služby na stránkách používáme. Tyto soubory cookies neukládají žádné informace přiřaditelné ke konkrétní osobě.</div>
                                    </div>
                                </div>
                                <div className="cookies-info-wrapper">
                                    <div className="cookies-info-button">
                                        <button className="btn btn-primary collapsed" type="button" data-toggle="collapse"
                                                data-target="#analyticsCookies" aria-expanded="false"
                                                aria-controls="analyticsCookies">
                                            <h4> Základní analytické cookies
                                            </h4>
                                        </button>
                                        <div className="custom-switch">
                                            <input type="checkbox"
                                                   className="custom-control-input"
                                                   checked={analyticsColapse}
                                                   onClick={() => setAnalyticsColapse(!analyticsColapse)}
                                                   id="analyticsCookiesSwitch" />
                                                <label className="custom-control-label" htmlFor="analyticsCookiesSwitch" />
                                        </div>
                                    </div>
                                    <div className="collapse" id="analyticsCookies">
                                        <div className="card card-body">Tyto cookies slouží ke zlepšení fungování Webových stránek. Umožňují nám rozpoznat a zjistit počet návštěvníků a sledovat, jak návštěvníci používají Webové stránky. Pomáhají nám zlepšovat způsob, jakým Webové stránky fungují, například tak, že umožňují uživatelům snadno najít to, co hledají. Tyto cookies neshromažďují informace, které by dokázaly identifikovat Vaši osobu. Pomocí těchto nástrojů analyzujeme a pravidelně zlepšujeme funkcionalitu našich Webových stránek. Získané statistiky můžeme použít ke zlepšení uživatelského komfortu a abychom učinili Vaši návštěvu Webových stránek zajímavější pro Vás jako uživatele.</div>
                                    </div>
                                </div>
                            </div>
                            <div id="cookies-footer-wrapper">
                                <hr className="cookies-divider"/>
                                <div className="cookies-footer">
                                    <button id="accept-cookies" onClick={saveCookies}>Povolit vše</button>
                                    <button id="save-cookies" onClick={saveCookies}>Uložit změny</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="cookies-overlay" className={cookiesOverlayClass} onClick={closeCookies} />
                </div>
            </div>
        </CookiesWrapper>
    );
};
